/**
 * Global Site object
 */
var Site = (function() {

    Home = {
        init: function() {
        }
    },

    Contact = {
        init: function() {
        }
    },

    /**
     * Initiate jQuery Accordion
     *
     * @returns void
     */
    initDatePicker = function() {
        var config = {
            dateFormat : 'yy-mm-dd',
            firstDay : 1,
            changeYear: true,
            changeMonth: true,
            dayNamesMin: [ "V", "H", "K", "Sze", "Cs", "P", "Szo" ],
            dayNamesShort: [ "Vas", "Hét", "Kedd", "Szer", "Csüt", "Pén", "Szo" ],
            monthNamesShort : [ "Jan", "Feb", "Már", "Ápr", "Máj", "Jún", "Júl", "Aug", "Szep", "Okt", "Nov", "Dec" ],
            monthNames : [ "Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December" ],
        };

        $(".datepicker").datepicker(config);
    }
    /**
     * Initiate jQuery Accordion
     *
     * @returns void
     */
    initBirthDayDatePicker = function() {
        var config = {
            dateFormat : 'yy-mm-dd',
            yearRange : '1950:2000',
            firstDay : 1,
            changeYear: true,
            changeMonth: true,
            dayNamesMin: [ "V", "H", "K", "Sze", "Cs", "P", "Szo" ],
            dayNamesShort: [ "Vas", "Hét", "Kedd", "Szer", "Csüt", "Pén", "Szo" ],
            monthNamesShort : [ "Jan", "Feb", "Már", "Ápr", "Máj", "Jún", "Júl", "Aug", "Szep", "Okt", "Nov", "Dec" ],
            monthNames : [ "Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December" ],
        };

        $(".datepicker").datepicker(config);
    }

    /**
     * Initiate tinyMCE
     *
     * @returns void
     */
    initTinyMce = function() {
        if ($('textarea.tiny-mce').length) {
            tinyMCE.init({
                mode : 'exact',
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table contextmenu paste"
                ],
                selector: 'textarea.tiny-mce'
            });
        }
    }

    return {


        Home: Home,

        /**
         * Global init
         *
         * @return void
         */
        init: function() {

            if (!activeCont) {
                throw new Exception('Unable to load active controller variable');
            }

            //pick the related object's init
            switch (activeCont) {
                case 'home':        Home.init();        break;
                case 'contact':     Contact.init();     break;
            }
        }
    }
}());

$(function() {
    Site.init();
});